import "./App.css";
import { getData } from "./service";
import React, { useEffect, useState } from "react";
import { Accordion, Badge, Card, ListGroup, Pagination } from "react-bootstrap";

import {
  FacebookFilled,
  GlobalOutlined,
  InstagramFilled,
  LinkedinFilled,
  MailOutlined,
  PhoneOutlined,
  PushpinOutlined,
  SearchOutlined,
  TwitterSquareFilled,
  YoutubeFilled,
} from "@ant-design/icons";
import { debounce, indexOf, wrap } from "lodash";
import FilterCenter from "./FilterCenter";
import "boxicons";

const Lists = ({ onShowElement }) => {
  const [industries, setIndustries] = useState([]);
  const [countries, setCountries] = useState([]);
  const [exhibitors, setExhibitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [countPage, setCountPage] = useState(0);
  const [selectedPage, setSelectedPage] = useState(null);

  const [filter, setFilter] = useState({
    industry_category: [],
    country: [],
    _fields:
      "id,title,content,exhibitor_name,logo,is_premium,banner_logo,description,country,link,industry_category,stand,level,fb_url,ig_url,twitter_url,yt_url,ig_url,linkedln_url,company_url,company_email,company_phone,address,gallery_image_1,gallery_image_2,gallery_title_1,gallery_title_2",
    search: "",
    search_first: "",
    find_level: "",
    is_premium: "0",
    exhibitor_name: "",
    order: "asc",
    orderby: "is_premium",
    page: 1,
    level: "",
    per_page: 9,
  });

  const handleFilterLevelExhibitors = async (data = "", event) => {
    setLoading(true);

    if (event && event.preventDefault) {
      event.preventDefault();
      console.log(data);
      await getData("ciptadusa_directory", {
        ...filter,
        industry_category:
          filter.industry_category.length > 0
            ? filter.industry_category.join(",")
            : null,
        country: filter.country.length > 0 ? filter.country.join(",") : null,
        find_level: data,
      })
        .then((res) => {
          setFilter({
            ...filter,
            level: data,
          });
          setExhibitors([]);
          setExhibitors(res.data ?? []);
          setCount(res.headers["x-wp-total"] ?? 0);
          setCountPage(res.headers["x-wp-totalpages"] ?? 0);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const loadExhibitors = async (data = "") => {
    setLoading(true);

    await getData("ciptadusa_directory", {
      ...filter,
      industry_category:
        filter.industry_category.length > 0
          ? filter.industry_category.join(",")
          : null,
      country: filter.country.length > 0 ? filter.country.join(",") : null,
      find_level: data !== "" ? data : "",
    })
      .then((res) => {
        setExhibitors(res.data ?? []);
        setCount(res.headers["x-wp-total"] ?? 0);
        setCountPage(res.headers["x-wp-totalpages"] ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadIndustryCategories = () => {
    getData("industry_category", {
      _fields: "id,name,slug,count",
      per_page: 100,
      order: "desc",
      orderby: "id",
    }).then((res) => {
      setIndustries(res.data ?? []);
    });
  };

  const loadCountries = () => {
    getData("country", {
      _fields: "id,name,slug,count",
      per_page: 100,
      order: "desc",
      orderby: "id",
    }).then((res) => {
      setCountries(res.data ?? []);
    });
  };

  const handlePagination = (page) => {
    setFilter({
      ...filter,
      page,
    });
  };

  const next = (event) => {
    if (event) {
      setFilter({
        ...filter,
        page: filter.page + 1,
      });
    }
  };

  const prev = (event) => {
    if (event) {
      event.preventDefault();
      setFilter({
        ...filter,
        page: filter.page - 1,
      });
    }
  };

  const first = (event) => {
    if (event) {
      event.preventDefault();
      setFilter({
        ...filter,
        page: 1,
      });
    }
  };

  const last = (event) => {
    if (event) {
      event.preventDefault();
      setFilter({
        ...filter,
        page: countPage,
      });
    }
  };

  const handleFilterIndustry = (e, id) => {
    const isOn = e.target.checked;
    const newFilter = { ...filter };
    if (isOn) {
      newFilter.industry_category.push(id);
    } else {
      newFilter.industry_category = newFilter.industry_category.filter(
        (item) => item !== id
      );
    }
    setFilter(newFilter);
  };
  const handleFilterIsPremium = async (event) => {
    setLoading(true);
    if (event && event.preventDefault) {
      event.preventDefault();
      setLoading(true);
      await getData("ciptadusa_directory", {
        ...filter,
        industry_category:
          filter.industry_category.length > 0
            ? filter.industry_category.join(",")
            : null,
        country: filter.country.length > 0 ? filter.country.join(",") : null,
        find_level: "major",
      })
        .then((res) => {
          setFilter({
            ...filter,
            level: "major",
          });
          setExhibitors([]);
          setExhibitors(res.data ?? []);
          setCount(res.headers["x-wp-total"] ?? 0);
          setCountPage(res.headers["x-wp-totalpages"] ?? 0);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  const handleFilterCountry = (e, id) => {
    const isOn = e.target.checked;
    const newFilter = { ...filter };
    if (isOn) {
      newFilter.country.push(id);
    } else {
      newFilter.country = newFilter.country.filter((item) => item !== id);
    }
    setFilter(newFilter);
  };

  const unescapeHtml = (unsafe) => {
    if (unsafe) {
      return new DOMParser().parseFromString(unsafe, "text/html")
        .documentElement.textContent;
    }
    return "";
  };
  const removeHtmlTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/<[^>]*>/g, "");
  };

  const limitText = (text, limit) => {
    if (text) {
      const newText = text.split(" ").splice(0, limit).join(" ");
      return newText;
    }
    return "";
  };

  const removeIndustryFilter = (id) => {
    const newFilter = { ...filter };
    newFilter.industry_category = newFilter.industry_category.filter(
      (item) => item !== id
    );
    setFilter(newFilter);
  };

  const removeCountryFilter = (id) => {
    const newFilter = { ...filter };
    newFilter.country = newFilter.country.filter((item) => item !== id);
    setFilter(newFilter);
  };

  const handleSearchWithDebounce = (e) => {
    const newFilter = { ...filter };
    newFilter.search = e.target.value === "" ? null : e.target.value;
    setFilter(newFilter);
  };

  const GeneratePagination = () => {
    const items = [];
    for (let number = 1; number <= countPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === filter.page}
          onClick={() => handlePagination(number)}
        >
          {number}
        </Pagination.Item>
      );
    }
    return items;
  };

  const detailExhibitor = (item) => {
    setSelectedPage(item);
  };

  const openLink = (url) => {
    window.open(url, "_blank");
  };

  const backToExhibitor = () => {
    setSelectedPage(null);
  };

  const handleSearchFirst = (e) => {
    const newFilter = {
      ...filter,
      page: 1,
      search_first: e === "All" ? null : e,
    };
    setFilter(newFilter);
  };

  useEffect(() => {
    loadIndustryCategories();
    loadCountries();
  }, []);

  useEffect(() => {
    loadExhibitors(filter.level);
  }, [filter]);

  // ...

  return (
   <>
      {selectedPage === null ? (
        <div className="row p-5">
          <div className="col-12">
            <div className="row">
              <div className="col-md-12 col-lg-4 col-xl-3 col-12">
                <div className="card empty-radius">
                  <div className="card-header empty-radius">
                    <span className="fw-bold">Filter</span>
                  </div>
                  <div className="card-body empty-radius">
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          className="fw-bold"
                          eventKey="0"
                        >
                          Industry Category
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            {industries.map((item) => (
                              <div className="form-check" key={item.id}>
                                <input
                                  className="m-2"
                                  type="checkbox"
                                  checked={filter.industry_category.includes(
                                    item.id
                                  )}
                                  onChange={(e) =>
                                    handleFilterIndustry(e, item.id)
                                  }
                                  id={`industry-${item.id}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`industry-${item.id}`}
                                >
                                  {item.name}{" "}
                                  <span className="text-gray">
                                    ({item.count})
                                  </span>
                                </label>
                              </div>
                            ))}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                      {/* <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          className="fw-bold"
                          eventKey="1"
                        >
                          Country / Region
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                          <Card.Body>
                            {countries.map((item) => (
                              <div className="form-check" key={item.id}>
                                <input
                                  className="m-2"
                                  type="checkbox"
                                  checked={filter.country.includes(item.id)}
                                  onChange={(e) =>
                                    handleFilterCountry(e, item.id)
                                  }
                                  id={`country-${item.id}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`country-${item.id}`}
                                >
                                  {item.name}{" "}
                                  <span className="text-gray">
                                    ({item.count})
                                  </span>
                                </label>
                              </div>
                            ))}
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card> */}
                    </Accordion>
                  </div>
                </div>
              </div>
              <div className="container-exhibitor my-md-4 col-md-12 col-lg-8 col-xl-9 col-12">
                <div className="row m-md-0 m-2">
                  <div className="col-12 mb-2">
                    <div className="row">
                      <div className="col-12 col-md-9 my-md-0 mt-4">
                        <input
                          type="text"
                          onChange={debounce(handleSearchWithDebounce, 500)}
                          className="form-control"
                          id="inputGroupFile04"
                        />
                      </div>
                      <div className="col-12 col-md-3">
                        <button
                          className="btn btn-block btn-outline-secondary no-radius"
                          type="button"
                        >
                          <SearchOutlined />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-2">
                    {filter.industry_category &&
                      filter.industry_category.map((id, key) => (
                        <Badge
                          bg="secondary"
                          onClick={() => removeIndustryFilter(id)}
                          className="m-2"
                          style={{ cursor: "pointer" }}
                          key={key}
                        >
                          <span aria-hidden="true" className="m-2">
                            &times;
                          </span>
                          {industries.find((item) => item.id === id)?.name}
                        </Badge>
                      ))}
                    {filter.country &&
                      filter.country.map((id, key) => (
                        <Badge
                          bg="secondary"
                          style={{ cursor: "pointer" }}
                          key={key}
                          onClick={() => removeCountryFilter(id)}
                          className="m-2"
                        >
                          <span aria-hidden="true" className="m-2">
                            &times;
                          </span>
                          {countries.find((item) => item.id === id)?.name}
                        </Badge>
                      ))}
                  </div>
                  <div
                    className="col-md-3 col-12 mb-2 mt-1 text-left"
                    style={{ fontSize: "large" }}
                  >
                    <span>Showing {count} results</span>
                  </div>
                  <div className="col-md-7 col-12 mb-2 mt-1 text-center">
                    <FilterCenter
                      value={
                        filter.search_first === "" ? "All" : filter.search_first
                      }
                      onChange={handleSearchFirst}
                    />
                  </div>
                  <div className="col-md-2 col-12 mb-2 mr-5">
                    {countPage > 1 && (
                      <Pagination style={{ fontSize: "large" }}>
                        <Pagination.First
                          className="mr-2"
                          disabled={filter.page === 1}
                          onClick={(event) => first(event)}
                        />
                        <Pagination.Prev
                          className="mr-2"
                          disabled={filter.page === 1}
                          onClick={(event) => prev(event)}
                        />
                        <Pagination.Next
                          className="mr-2"
                          disabled={filter.page == countPage}
                          onClick={(event) => next(event)}
                        />
                        <Pagination.Last
                          className="mr-2"
                          disabled={filter.page == countPage}
                          onClick={(event) => last(event)}
                        />
                      </Pagination>
                    )}
                  </div>

                  <div className="row mb-3 pl-4 w-100">
                    <a
                      onClick={(event) => loadExhibitors()}
                      href=""
                      className={`col-md-1 my-sm-2 my-2 mr-3 col-3 box-level ${
                        filter.level === "" ? "box-up" : ""
                      }`}
                      style={{ color: "black", backgroundColor: "#f6f6f6" }}
                    >
                      All
                    </a>
                    <a
                      onClick={(event) =>
                        handleFilterLevelExhibitors("titanium", event)
                      }
                      href=""
                      className={`col-md-2 my-sm-2 my-2 mr-3 col-3 box-level ${
                        filter.level === "titanium" ? "box-up" : ""
                      }`}
                      style={{ backgroundColor: "#d00a3f" }}
                    >
                      Titanium
                    </a>

                    <a
                      onClick={(event) =>
                        handleFilterLevelExhibitors("gold", event)
                      }
                      href=""
                      className={`col-md-2 my-sm-2 my-2 mr-3 col-3 box-level ${
                        filter.level === "gold" ? "box-up" : ""
                      }`}
                      style={{ backgroundColor: "#fcd36c" }}
                    >
                      Gold
                    </a>

                   
                    <a
                      onClick={(event) =>
                        handleFilterLevelExhibitors("silver", event)
                      }
                      href=""
                      className={`col-md-2 my-sm-2 my-2 mr-3 col-3 box-level ${
                        filter.level === "silver" ? "box-up" : ""
                      }`}
                      style={{ backgroundColor: "#e1e2ee" }}
                    >
                      Silver
                    </a>

                    <a
                      onClick={(event) =>
                        handleFilterLevelExhibitors("bronze", event)
                      }
                      href=""
                      className={`col-md-2 my-sm-2 my-2 mr-3 col-3 box-level ${
                        filter.level === "bronze" ? "box-up" : ""
                      }`}
                      style={{ backgroundColor: "#59c48a" }}
                    >
                      Bronze
                    </a>

                    <a
                      onClick={(event) => handleFilterIsPremium(event)}
                      href=""
                      className={`col-md-2 my-sm-2 my-2 mr-3 col-3 box-level ${
                        filter.level === "major" ? "box-up" : ""
                      }`}
                      style={{ backgroundColor: "#a286f3" }}
                    >
                      Major
                    </a>
                  </div>

                  {loading ? (
                    <div className="col-12 text-center mb-2">Loading ....</div>
                  ) : (
                    <div className="row w-100 pl-4">
                      {exhibitors &&
                        exhibitors.length > 0 &&
                        exhibitors.map((item, key) => (
                          <div
                            className="card mb-0 pb-0 border rounded-5 col-md-5 col-lg-5 col-xl-3  col-12 m-2 p-4"
                            width={200}
                            height={100}
                            key={key}
                          >
                            <img
                              src={item.logo}
                              alt="Logo"
                              width={100}
                              height={100}
                              className="card-img-top"
                              style={{ objectFit: "contain" }}
                            ></img>
                            <div className="card-body mb-0 pb-0 p-0">
                              <div
                                className="card-title mt-4"
                                style={{ fontSize: "smaller" }}
                              >
                                <a
                                  href=""
                                  onClick={() => onShowElement(item, "detail")}
                                  style={{
                                    textDecoration: "none",
                                    color: "black",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item.exhibitor_name}
                                </a>
                              </div>
                            </div>
                            <div className="card-footer p-0 mt-2 border-0 pb-0 row bg-transparent">
                              <div className="col-6 p-0 d-flex justify-content-start align-items-center">
                                {" "}
                                <span>
                                  <box-icon name="pin"></box-icon>
                                </span>
                                <h5 style={{ fontSize: "smaller" }}>
                                  {item.stand &&
                                    item.stand.split(",").map((items, key) => (
                                      <span key={key}>
                                        <a
                                          href=""
                                          onClick={() =>
                                            onShowElement(items, "booth")
                                          }
                                        >
                                          {items}
                                        </a>
                                      </span>
                                    ))}
                                </h5>
                              </div>
                              <div className="col-6 p-0 d-flex justify-content-center align-items-center">
                                {" "}
                                <h5 style={{ fontSize: "smaller" }}>
                                  {item?.level == "" ||
                                  item?.level == "major" ? (
                                    <div className={`empty-radius`}>
                                      <span style={{ fontSize: "smaller" }}>
                                        Major Exhibitor{" "}
                                      </span>
                                    </div>
                                  ) : (
                                    <span style={{ fontSize: "smaller" }}>
                                      {item.level} Exhibitor{" "}
                                    </span>
                                  )}
                                </h5>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="row p-5" id="exhibitor-detail">
            <div className="col-12 mb-2">
              <span
                style={{
                  cursor: "pointer",
                }}
                className="text-secondary"
                onClick={backToExhibitor}
              >
                {" < "} Back to Exhibitor List
              </span>
            </div>
            <div className="col-12 col-md-9">
              <div className="card">
                <div className="card-body">
                  <div className="row mb-3">
                    <div className="col-12">
                      <span className="fs-6 fw-bold">Company Name</span>
                    </div>
                    <div className="col-12">
                      <p>{selectedPage?.exhibitor_name}</p>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-12">
                      <span className="fs-6 fw-bold">Description</span>
                    </div>
                    <div className="col-12">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: unescapeHtml(selectedPage?.content?.rendered),
                        }}
                      />
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-1">
                    <div className="col-12">
                      <span className="fs-6 fw-bold">Country / Region</span>
                    </div>
                    <div className="col-12">
                      {countries
                        .filter((e) => selectedPage?.country.includes(e.id))
                        .map((e) => e.name)
                        .map((_name) => (
                          <Badge bg="secondary">{unescapeHtml(_name)}</Badge>
                        ))}
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-1">
                    <div className="col-12">
                      <span className="fs-6 fw-bold">Industry Sector</span>
                    </div>
                    <div className="col-12">
                      {industries
                        .filter((e) =>
                          selectedPage?.industry_category.includes(e.id)
                        )
                        .map((e) => e.name)
                        .map((_name) => (
                          <Badge bg="secondary">{unescapeHtml(_name)}</Badge>
                        ))}
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-1">
                    <div className="col-12">
                      <span className="fs-6 fw-bold">Country / Region</span>
                    </div>
                    <div className="col-12">
                      {countries
                        .filter((e) => selectedPage?.country.includes(e.id))
                        .map((e) => e.name)
                        .map((_name) => (
                          <Badge bg="secondary">{unescapeHtml(_name)}</Badge>
                        ))}
                    </div>
                  </div>
                  <hr />
                  <div className="row mb-1">
                    <div className="col-12">
                      <span className="fs-4 fw-bold">
                        Gallery of products and services
                      </span>
                    </div>
                    <div className="col-12">
                      <div className="row g-2">
                        <div className="col-md-3 col-12 text-center">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={selectedPage?.gallery_image_1}
                            />
                            <Card.Body>
                              <Card.Title>
                                {selectedPage?.gallery_title_1}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </div>
                        <div className="col-md-3 col-12 text-center">
                          <Card>
                            <Card.Img
                              variant="top"
                              src={selectedPage?.gallery_image_2}
                            />
                            <Card.Body>
                              <Card.Title>
                                {selectedPage?.gallery_title_2}
                              </Card.Title>
                            </Card.Body>
                          </Card>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="row">
                <div className="col-12">
                  <div className="card profile-nav">
                    <div
                      className="user-heading round"
                      style={{
                        backgroundImage: `url(${selectedPage?.banner_logo})`,
                      }}
                    >
                      <a href="#">
                        <img src={selectedPage?.logo} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <div className="card bg-section text-black">
                    <div className="row m-3">
                      <div className="col">
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => openLink(selectedPage?.fb_url)}
                        >
                          <FacebookFilled
                            style={{
                              fontSize: 30,
                              color: "#3b5998",
                            }}
                          />
                        </span>
                      </div>
                      <div className="col">
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => openLink(selectedPage?.twitter_url)}
                        >
                          <TwitterSquareFilled
                            style={{
                              fontSize: 30,
                              color: "#00acee",
                            }}
                          />
                        </span>
                      </div>
                      <div className="col">
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => openLink(selectedPage?.yt_url)}
                        >
                          <YoutubeFilled
                            style={{
                              fontSize: 30,
                              color: "#ff0000",
                            }}
                          />
                        </span>
                      </div>
                      <div className="col">
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => openLink(selectedPage?.ig_url)}
                        >
                          <InstagramFilled
                            style={{
                              fontSize: 30,
                              color: "#c13584",
                            }}
                          />
                        </span>
                      </div>
                      <div className="col">
                        <span
                          style={{
                            cursor: "pointer",
                          }}
                          onClick={() => openLink(selectedPage?.linkedln_url)}
                        >
                          <LinkedinFilled
                            style={{
                              fontSize: 30,
                              color: "#0e76a8",
                            }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 mt-2">
                  <div className="card bg-section text-black">
                    <div className="card-body d-flex justify-content-left">
                      <PushpinOutlined
                        style={{
                          marginTop: 4,
                          marginRight: 6,
                          fontWeight: "bold",
                        }}
                      />
                      <span>
                        <span className="fw-bold">Stand : </span>
                        {selectedPage?.stand}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-2">
                  <div className="card bg-section text-black">
                    <div className="card-body">
                      <div className="row mb-2">
                        <div className="col-12">
                          <span className="fw-bold text-uppercase">
                            Company Website
                          </span>
                        </div>
                        <div className="col-12">
                          <a
                            href={selectedPage?.company_url}
                            className="text-decoration-none text-primary"
                          >
                            {selectedPage?.company_url === "#" ||
                            selectedPage?.company_url === null
                              ? "-"
                              : selectedPage?.company_url}
                          </a>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-12">
                          <span className="fw-bold text-uppercase">
                            Company Email
                          </span>
                        </div>
                        <div className="col-12">
                          <a
                            href={`mailto:${selectedPage?.company_email}`}
                            className="text-decoration-none text-primary"
                          >
                            {selectedPage?.company_email === "#" ||
                            selectedPage?.company_email === null ||
                            selectedPage?.company_email === undefined
                              ? "-"
                              : selectedPage?.company_email}
                          </a>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-12">
                          <span className="fw-bold text-uppercase">
                            Company Phone
                          </span>
                        </div>
                        <div className="col-12">
                          <a
                            href={`tel:${selectedPage?.company_phone}`}
                            className="text-decoration-none text-primary"
                          >
                            {selectedPage?.company_phone === "#" ||
                            selectedPage?.company_phone === null
                              ? ""
                              : selectedPage?.company_phone}
                          </a>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-12">
                          <span className="fw-bold text-uppercase">
                            Address
                          </span>
                        </div>
                        <div className="col-12">
                          {selectedPage?.address ?? "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
 </> 
  );
};

const Detail = ({ onShowElement, onData, onBack }) => {
  const [exhibitors, setExhibitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [countPage, setCountPage] = useState(0);
  const [popUp, setPopUp] = useState("false");
  const [popUpData, setPopUpData] = useState("");
  const [filter, setFilter] = useState({
    industry_category: [],
    country: [],
    _fields:
      "id,title,content,exhibitor_name,logo,is_premium,banner_logo,description,country,link,industry_category,stand,level,fb_url,ig_url,twitter_url,yt_url,ig_url,linkedln_url,company_url,company_email,company_phone,address,gallery_image_1,gallery_image_2,gallery_title_1,gallery_title_2",
    search: "",
    search_first: "",
    find_level: "",
    is_premium: "0",
    exhibitor_name: "",
    order: "asc",
    orderby: "is_premium",
    page: 1,
    level: "",
    per_page: 100,
  });
  const data = JSON.parse(onData);
  const loadExhibitors = async (data = "") => {
    setLoading(true);

    await getData("ciptadusa_directory", {
      ...filter,

      find_level: data !== "" ? data.level : "",
    })
      .then((res) => {
        setExhibitors(res.data ?? []);
        setCount(res.headers["x-wp-total"] ?? 0);
        setCountPage(res.headers["x-wp-totalpages"] ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handlePopUp = (data, nav, event) => {
    event.preventDefault();
    setPopUp(data);
    setPopUpData(nav);
  };

  useEffect(() => {
    loadExhibitors(data);
  }, [filter]);
  const unescapeHtml = (unsafe) => {
    if (unsafe) {
      return new DOMParser().parseFromString(unsafe, "text/html")
        .documentElement.textContent;
    }
    return "";
  };
  const removeHtmlTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/<[^>]*>/g, "");
  };

  return (
    <div className={popUp == "true" ? "cut" : ""}>
      {popUp === "true" && (
        <div className="popup" style={{ color: "white", padding: "50px" }}>
          <a
            href=""
            style={{ color: "white", textDecoration: "none",marginBottom:"20px" }}
            onClick={(event) => handlePopUp("false", "",event)}
          >
            <h1 style={{ float: "right" }}>&times;</h1>
          </a>
          {popUpData === "gallery_image_1" && (
            <img src={data.gallery_image_1} className="popup-img"></img>
          )}

          {popUpData === "gallery_image_2" && (
            <img src={data.gallery_image_2} className="popup-img"></img>
          )}
        </div>
      )}

      <div className="container mb-5">
        <div className="row detail-context d-flex mt-5 ">
          <div className="col-md-12 col-lg-8 col-12">
            <div className="mb-4">
              <a
                href=""
                onClick={onBack}
                style={{ textDecoration: "none", color: "black" }}
              >
                <span className="text-secondary">
                  {" < "} Back to Exhibitor List
                </span>
              </a>
            </div>

            <div
              className="card-details card  pb-5 p-0"
              width={500}
              style={{ height: "max-content" }}
            >
              <div
                className="card-img-top m-0"
                style={{
                  width: "100%",
                  height: "300px",
                  backgroundColor:
                    data.banner_logo == "" ? "#dddddd" : "transparent",
                  backgroundImage:
                    data.banner_logo == ""
                      ? "none"
                      : `url(${data.banner_logo})`,
                  objectFit: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <img
                  src={data.logo}
                  alt="Logo"
                  style={{
                    width: "150px",
                    height: "150px",
                    padding: "10px",
                    objectFit: "contain",
                    backgroundColor: "#f3f3f3",
                    borderRadius: "10px",
                    marginTop: "200px",
                    marginLeft: "20px",
                  }}
                ></img>
              </div>
              <div className="card-body">
                <div style={{ marginLeft: "170px" }}>
                  <h1 className="card-title" style={{ fontSize: "large" }}>
                    {data.exhibitor_name}
                  </h1>
                  {/* <div className="col-6 p-0 d-flex justify-content-center align-items-center"> */}
                  <h5 style={{ fontSize: "large" }}>
                    {data.level === "" || data.level === "major" ? (
                      <div className={`empty-radius`}>
                        <span style={{ fontSize: "smaller" }}>
                          Major Exhibitor{" "}
                        </span>
                      </div>
                    ) : (
                      <span style={{ fontSize: "smaller" }}>
                        {data.level} Exhibitor{" "}
                      </span>
                    )}
                  </h5>
                  {/* </div> */}
                  <h3
                    style={{
                      display: "flex",
                      alignItems: "center",
                      fontSize: "large",
                    }}
                  >
                    <span className="mr-1">
                      <box-icon name="pin" size="sm"></box-icon>
                    </span>
                    {data.stand}
                  </h3>

                  <hr></hr>

                  <h3 className="text-muted">Description</h3>
                  <p style={{ textAlign: "justify", textIndent: "20px" }}>
                    {removeHtmlTags(
                      unescapeHtml(data?.content?.rendered ?? "")
                    )}
                  </p>
                </div>
                <hr></hr>
                {/* <div>
                  <h3 className="mb-3">Social Media</h3>
                  <span>
                    <div
                      className="p-5 border"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <a
                        href={data.twitter_url}
                        style={{ textDecoration: "none" }}
                      >
                        <box-icon type="logo" name="twitter"></box-icon>
                      </a>

                      <a href={data.fb_url} style={{ textDecoration: "none" }}>
                        <box-icon name="facebook" type="logo"></box-icon>
                      </a>

                      <a
                        href={data.linkedln_url}
                        style={{ textDecoration: "none" }}
                      >
                        <box-icon name="linkedin" type="logo"></box-icon>
                      </a>

                      <a href={data.ig_url} style={{ textDecoration: "none" }}>
                        <box-icon type="logo" name="instagram"></box-icon>
                      </a>

                      <a href={data.yt_url} style={{ textDecoration: "none" }}>
                        <box-icon name="youtube" type="logo"></box-icon>
                      </a>

                      <a href={data.link} style={{ textDecoration: "none" }}>
                        <box-icon name="globe" type="logo"></box-icon>
                      </a>
                    </div>
                  </span>
                </div> */}
                {/* <hr></hr> */}

                <h3 className="mb-3">Contact Details</h3>
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th style={{ textAlign: "end" }}>Email</th>
                        <td>{data.company_email}</td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "end" }}>Phone</th>
                        <td>{data.company_phone}</td>
                      </tr>
                      <tr>
                        <th style={{ textAlign: "end" }}>Address</th>
                        <td>{data.address}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <hr></hr>

                <h3 className="mb-3">Country / Region</h3>
                <h5>{data.country}</h5>
                <hr></hr>
                <h3 className="mb-3">Industry Sector</h3>
                <h5>{data.industry_category}</h5>
                <hr></hr>
                <div></div>
                <h3>Gallery</h3>
                <div
                  className="mt-3"
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <img
                    onClick={(event) => handlePopUp("true", "gallery_image_1",event)}
                    src={data.gallery_image_1}
                    style={{
                      width: "200px",
                      height: "200px",
                      cursor: "pointer",
                    }}
                    alt="gallery1"
                  ></img>
                  <img
                    onClick={(event) => handlePopUp("true", "gallery_image_2",event)}
                    src={data.gallery_image_2}
                    style={{
                      width: "200px",
                      height: "200px",
                      cursor: "pointer",
                    }}
                    alt="gallery2"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 col-12 my-4">
            <h3 className="references-title">Other Exhibitors</h3>

            {loading ? (
              <div>Loading ....</div>
            ) : (
              <div className="references border px-2 mt-4 py-2">
                {exhibitors &&
                  exhibitors.length > 0 &&
                  exhibitors.map((item, key) => (
                    <div className="row my-3 w-100 pl-4" key={key}>
                      <div className="card mb-0 pb-0 border rounded-5 col-md-12 col-12 m-2 p-4">
                        <img
                          src={item.logo}
                          alt="Logo"
                          width={100}
                          height={100}
                          className="card-img-top"
                          style={{ objectFit: "contain" }}
                        ></img>
                        <div className="card-body mb-0 pb-0 p-0">
                          <div
                            className="card-title mt-4"
                            style={{ fontSize: "smaller" }}
                          >
                            <a
                              href=""
                              onClick={() => onShowElement(item, "detail")}
                              style={{
                                textDecoration: "none",
                                color: "black",
                                fontWeight: "bold",
                              }}
                            >
                              {item.exhibitor_name}
                            </a>
                          </div>
                        </div>
                        <div className="card-footer p-0 mt-2 border-0 pb-0 row bg-transparent">
                          <div className="col-6 p-0 d-flex justify-content-start align-items-center">
                            <span>
                              <box-icon name="pin"></box-icon>
                            </span>
                            <h5 style={{ fontSize: "smaller" }}>
                              {item.stand}
                            </h5>
                          </div>
                          <div className="col-6 p-0 d-flex justify-content-center align-items-center">
                            <h5 style={{ fontSize: "smaller" }}>
                              {item?.level === "" || item?.level === "major" ? (
                                <div className={`empty-radius`}>
                                  <span style={{ fontSize: "smaller" }}>
                                    Major Exhibitor{" "}
                                  </span>
                                </div>
                              ) : (
                                <span style={{ fontSize: "smaller" }}>
                                  {item.level} Exhibitor{" "}
                                </span>
                              )}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Booth = ({ onData, onBack, onShowElement }) => {
  const [exhibitors, setExhibitors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [countPage, setCountPage] = useState(0);

  const [filter, setFilter] = useState({
    industry_category: [],
    country: [],
    _fields:
      "id,title,content,exhibitor_name,logo,is_premium,banner_logo,description,country,link,industry_category,stand,level,fb_url,ig_url,twitter_url,yt_url,ig_url,linkedln_url,company_url,company_email,company_phone,address,gallery_image_1,gallery_image_2,gallery_title_1,gallery_title_2",
    search: "",
    search_first: "",
    find_level: "",
    is_premium: "0",
    exhibitor_name: "",
    order: "asc",
    orderby: "is_premium",
    page: 1,
    level: "",
    per_page: 100,
  });
  const data2 = JSON.parse(onData);

  const loadExhibitors = async (data) => {
    setLoading(true);

    await getData("ciptadusa_directory", {
      ...filter,
      industry_category:
        filter.industry_category.length > 0
          ? filter.industry_category.join(",")
          : null,
      country: filter.country.length > 0 ? filter.country.join(",") : null,
      find_stand: data,
    })
      .then((res) => {
        setExhibitors(res.data ?? []);
        setCount(res.headers["x-wp-total"] ?? 0);
        setCountPage(res.headers["x-wp-totalpages"] ?? 0);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadExhibitors(data2);
  }, [filter]);
  const unescapeHtml = (unsafe) => {
    if (unsafe) {
      return new DOMParser().parseFromString(unsafe, "text/html")
        .documentElement.textContent;
    }
    return "";
  };
  const removeHtmlTags = (str) => {
    if (str === null || str === "") return false;
    else str = str.toString();
    return str.replace(/<[^>]*>/g, "");
  };

  return (
    <div
      className="container border p-4"
      style={{ width: "100%", minHeight: "100vh" }}
    >
      <div className="row">
        <div className="mb-4 mt-4">
          <a
            href=""
            onClick={onBack}
            style={{ textDecoration: "none", color: "black" }}
          >
            <span className="text-secondary">
              {" < "} Back to Exhibitor List
            </span>
          </a>
        </div>
      </div>

      <div className="mb-4">
        {loading ? (
          <div className="col-12 text-center mb-2">Loading ....</div>
        ) : exhibitors ? (
          exhibitors.length > 0 ? (
            <div className="row w-100 pl-4">
              {exhibitors.map((item, key) => (
                <div
                  className="card mb-0 pb-0 border rounded-5 col-md-5 col-lg-5 col-xl-3 col-12 m-2 p-4"
                  width={200}
                  height={100}
                  key={key}
                >
                  <img
                    src={item.logo}
                    alt="Logo"
                    width={100}
                    height={100}
                    className="card-img-top"
                    style={{ objectFit: "contain" }}
                  ></img>
                  <div className="card-body mb-0 pb-0 p-0">
                    <div
                      className="card-title mt-4"
                      style={{ fontSize: "smaller" }}
                    >
                      <a
                        href=""
                        onClick={() => onShowElement(item, "detail")}
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontWeight: "bold",
                        }}
                      >
                        {item.exhibitor_name}
                      </a>
                    </div>
                  </div>
                  <div className="card-footer p-0 mt-2 border-0 pb-0 row bg-transparent">
                    <div className="col-6 p-0 d-flex justify-content-start align-items-center">
                      {" "}
                      <span>
                        <box-icon name="pin"></box-icon>
                      </span>
                      <h5 style={{ fontSize: "smaller" }}>{item.stand}</h5>
                    </div>
                    <div className="col-6 p-0 d-flex justify-content-center align-items-center">
                      {" "}
                      <h5 style={{ fontSize: "smaller" }}>
                        {item?.level === "" || item?.level === "major" ? (
                          <div className={`empty-radius`}>
                            <span style={{ fontSize: "smaller" }}>
                              Major Exhibitor{" "}
                            </span>
                          </div>
                        ) : (
                          <span style={{ fontSize: "smaller" }}>
                            {item.level} Exhibitor{" "}
                          </span>
                        )}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <center>
              <div>Data tidak ada</div>
            </center>
          )
        ) : null}
      </div>
    </div>
  );
};

function App() {
 
  // const [data, setData] = useState([]);

  const showElement = localStorage.getItem("showElement");
  const data = localStorage.getItem("data");

  const handleShowElement = (data, nav) => {
    // setData(data);
    // if(data.exhibitor_name){
    localStorage.setItem("data", JSON.stringify(data));
    // }else if(!data.exhibitor_name){
    //   localStorage.setItem("data", data);
    // }
    localStorage.setItem("showElement", nav);
  };

  const handleBack = () => {
    localStorage.setItem("showElement", "lists");
    localStorage.removeItem("data");
  };
  useEffect(() => {
    localStorage.setItem("showElement", 'lists');
return()=>{

}
  }, []);

  return (
    <div id="directory-container">
      <div id="exhibitor-container">
   
      {showElement === "detail" && (
        <Detail
        onShowElement={handleShowElement}
        onData={data}
        onBack={handleBack}
        />
        )}
      {showElement === "booth" && (
        <div>
          <Booth
            onData={data}
            onBack={handleBack}
            onShowElement={handleShowElement}
            />
        </div>
      )}
      {showElement === "lists" && (
        <div>
          <Lists onShowElement={handleShowElement} />
        </div>
      )}
      </div>
    </div>
  );
}

export default App;
